<template>
    <el-container>
        <el-header height="auto" class="header"><shop-header /></el-header>
        <el-main>
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import ShopHeader from "./components/ShopHeader"

    export default {
        name: "Layout",
        components: {
            ShopHeader
        },
        created() {

        },
        data: () => {
            return {
                loadingAd: true,
                adList: [],
                is_show: true,
                indexTopAdNum: 0
            }
        },
        mounted() {},
        computed: {},
        watch: {},
        methods: {

        }
    }
</script>
<style lang="scss" scoped>
    .header {
        padding: 0;
    }
</style>
