import BasicLayout from "../../layout/basic"

const cmsRoutes = [{
		path: "/cms/notice",
		name: "notice",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/list")
	},
	{
		path: "/cms/notice-*",
		name: "notice_detail",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/detail")
	},
	{
		path: "/cms/help",
		name: "help",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/list"),
		children: [{
			path: "/cms/help/listother-*",
			name: "list_other",
			meta: {
				module: "help",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/help/listother")
		}]
	},
	{
		path: "/cms/help-*",
		name: "help_detail",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/detail")
	}
]

const goodsRoutes = [
	{
		path: "/sku-*",
		name: "detail",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/detail")
	},
	{
		path: "/list",
		name: "list",
		meta: {
			module: "goods",
			title: '商品列表',
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	},
	{
		path: "/street",
		name: "street",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '店铺街'
		},
		component: () => import("@/views/shop/street")
	},
]

// 其他模块
export default {
	path: "/",
	component: BasicLayout,
	redirect: "/shop-6",
	name: "Index",
	children: [{
			path: "/index*",
			name: "index",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/shop/index")
		},
		{
			path: "/change_city",
			name: "change_city",
			meta: {
				module: "index"
			},
			component: () => import("@/views/index/change_city")
		},

		...goodsRoutes,
		...cmsRoutes
	]
}
